var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToHome },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(_vm._s(_vm.$t("default.FOOTER_ACCESS")) + " ")]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "container-fluid px-md-1 px-lg-4 px-1 login-forms",
        style: {
          padding: "0 0",
          backgroundImage: `url(${require("@/assets/home-bg.jpg")})`,
          backgroundSize: "cover",
          "background-repeat": "no-repeat",
          "background-position": "right",
          "min-height": "80vh",
        },
      },
      [
        _c("div", { staticClass: "container-fluid mb-5" }, [
          _c(
            "div",
            {
              staticClass: "row text-start margen-estandard pt-5 pb-5 pb-md-3",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column pt-3 col-12 col-md-6 mb-3 pb-4 px-3",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "py-4 px-3",
                      style: { backgroundColor: "rgba(0,0,0,0.3)" },
                    },
                    [
                      _c("div", { staticClass: "row pt-2" }, [
                        _c("div", { staticClass: "col-12 col-md-10 mx-auto" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-center spainhn-subtitle-2-black",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.ACCESS_TITLE_HEADER"))
                              ),
                            ]
                          ),
                          _c("p", { staticClass: "spainhn-text text-center" }, [
                            _vm._v(_vm._s(_vm.$t("default.ACCESS_SUBTITLE"))),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-10 col-12 mx-auto" },
                          [
                            _c(
                              "b-form",
                              [
                                _c("b-form-input", {
                                  staticClass: "my-2",
                                  attrs: {
                                    name: "username",
                                    state: _vm.emailState,
                                    placeholder: _vm.$t(
                                      "default.ACCESS_EMAIL_PLACEHOLDER"
                                    ),
                                    type: "email",
                                  },
                                  model: {
                                    value: _vm.username,
                                    callback: function ($$v) {
                                      _vm.username = $$v
                                    },
                                    expression: "username",
                                  },
                                }),
                                _c("b-form-input", {
                                  staticClass: "my-2",
                                  attrs: {
                                    name: "password",
                                    state: _vm.passwordState,
                                    placeholder: _vm.$t(
                                      "default.ACCESS_PASSWORD_PLACEHOLDER"
                                    ),
                                    type: "password",
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function ($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "w-100" },
                                  [
                                    _c(
                                      "mdb-btn",
                                      {
                                        staticClass:
                                          "spainhn-button w-100 py-3 spainhn-text",
                                        staticStyle: { "font-size": "16pt" },
                                        attrs: { color: "indigo", block: "" },
                                        on: { click: _vm.login },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("default.ACCESS_TITLE"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-center",
                                    attrs: { href: "/recuperar-password" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("default.RESET_PASSWORD"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "col-md-6 col-12 px-3 pt-3" },
                [
                  _c("login-component", {
                    attrs: {
                      titulo: _vm.$t("default.FOOTER_ACCESS"),
                      url: _vm.adminUrl,
                      buttonText: _vm.$t("default.ACCESS_TITLE"),
                    },
                  }),
                  _c("login-component", {
                    staticClass: "mt-2",
                    attrs: {
                      titulo: _vm.$t("default.TOURISM_PROMOTERS"),
                      url: _vm.adminUrl,
                      buttonText: _vm.$t("default.ACCESS_TITLE"),
                    },
                  }),
                  _c("login-component", {
                    staticClass: "mt-2",
                    attrs: {
                      titulo: _vm.$t("default.ACCESS_MAYORISTAS_TITLE"),
                      url: _vm.mayoristasUrl,
                      buttonText: _vm.$t("default.ACCESS_TITLE"),
                    },
                  }),
                  _c("login-component", {
                    staticClass: "mt-2",
                    attrs: {
                      titulo: _vm.$t("default.ACCESS_SOCIOS_REGISTER"),
                      register: true,
                      goTo: _vm.registroMonumentos,
                      buttonText: _vm.$t("default.ACCESS_REGISTER"),
                    },
                  }),
                  _c("login-component", {
                    staticClass: "mt-2",
                    attrs: {
                      titulo: _vm.$t("default.ACCESS_MAYORISTAS_REGISTER"),
                      register: true,
                      goTo: _vm.registroMayoristas,
                      buttonText: _vm.$t("default.ACCESS_REGISTER"),
                    },
                  }),
                  _c("login-component", {
                    staticClass: "mt-2",
                    attrs: {
                      titulo: _vm.$t("default.SERVICE_PROVIDERS_REGISTER"),
                      register: true,
                      goTo: "/registro/promotor",
                      buttonText: _vm.$t("default.ACCESS_REGISTER"),
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _c(
          "b-modal",
          {
            ref: "error-acceso",
            attrs: {
              title: "Error",
              "hide-header": "",
              "hide-header-close": "",
            },
          },
          [
            _c("div", { staticClass: "d-flex" }, [
              _c("p", { staticClass: "text-center spainhn-subtitle-2-black" }, [
                _vm._v(_vm._s(_vm.errorDeAcceso)),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }