<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToHome">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.FOOTER_ACCESS')}} </p>
            </div>
        </div>
        <div class="container-fluid px-md-1 px-lg-4 px-1 login-forms" :style="{padding:'0 0', backgroundImage:`url(${require('@/assets/home-bg.jpg')})`, backgroundSize:'cover', 'background-repeat': 'no-repeat','background-position': 'right', 'min-height':'80vh'}">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard pt-5 pb-5 pb-md-3">

                    <div class="d-flex flex-column pt-3 col-12 col-md-6 mb-3 pb-4 px-3" >
                        <div :style="{backgroundColor:'rgba(0,0,0,0.3)'}" class="py-4 px-3">
                            <div class="row pt-2">
                                <div class="col-12 col-md-10 mx-auto">
                                    <p class="text-center spainhn-subtitle-2-black">{{$t('default.ACCESS_TITLE_HEADER')}}</p>
                                    <p class="spainhn-text text-center">{{$t('default.ACCESS_SUBTITLE')}}</p>
                                </div>
                            </div> 

                            <div class="row">
                                <div class="col-md-10 col-12 mx-auto">
                                    <b-form>
                                        <b-form-input name="username" :state="emailState" v-model="username" :placeholder="$t('default.ACCESS_EMAIL_PLACEHOLDER')" class="my-2" type="email"/>
                                        <b-form-input name="password" :state="passwordState" v-model="password" :placeholder="$t('default.ACCESS_PASSWORD_PLACEHOLDER')" class="my-2" type="password"/>
                                        <div class="w-100">
                                            <mdb-btn color="indigo" class="spainhn-button w-100 py-3 spainhn-text" style="font-size:16pt" block @click="login">{{$t('default.ACCESS_TITLE')}}</mdb-btn>
                                        </div>

                                        <a href="/recuperar-password" class="text-center">{{ $t('default.RESET_PASSWORD') }}</a>

                                    </b-form>
                                </div>
                            </div>    

                        </div>                        
                    </div>

                    <div class="col-md-6 col-12 px-3 pt-3">
                        <!-- <div class="col-12">
                            <div :style="{backgroundColor:'rgba(0,0,0,0.3)'}" class="py-4 px-2">
                                <p class="text-center spainhn-subtitle-2-black">{{$t('default.FOOTER_ACCESS')}}</p>
                                <div class="w-100 d-flex">
                                    <mdb-btn color="indigo" class="spainhn-button mx-auto w-75 py-3 spainhn-text" style="font-size:16pt" block :href="adminUrl">{{$t('default.ACCESS_TITLE')}}</mdb-btn>
                                </div>
                            </div>
                        </div> -->
                        <login-component :titulo="$t('default.FOOTER_ACCESS')" :url="adminUrl"  :buttonText="$t('default.ACCESS_TITLE')"></login-component>

                        <login-component class="mt-2" :titulo="$t('default.TOURISM_PROMOTERS')" :url="adminUrl"  :buttonText="$t('default.ACCESS_TITLE')"></login-component>

                        <login-component class="mt-2" :titulo="$t('default.ACCESS_MAYORISTAS_TITLE')" :url="mayoristasUrl" :buttonText="$t('default.ACCESS_TITLE')"></login-component>

                        <login-component class="mt-2" :titulo="$t('default.ACCESS_SOCIOS_REGISTER')" :register="true" :goTo="registroMonumentos" :buttonText="$t('default.ACCESS_REGISTER')"></login-component>

                        <login-component class="mt-2" :titulo="$t('default.ACCESS_MAYORISTAS_REGISTER')" :register="true" :goTo="registroMayoristas" :buttonText="$t('default.ACCESS_REGISTER')"></login-component>

                        <login-component class="mt-2" :titulo="$t('default.SERVICE_PROVIDERS_REGISTER')" :register="true" :goTo="'/registro/promotor'" :buttonText="$t('default.ACCESS_REGISTER')"></login-component>

                    </div>
                </div>
            </div>

            <b-modal ref="error-acceso"
                title="Error" hide-header hide-header-close>
                <div class="d-flex">
                    <p class="text-center spainhn-subtitle-2-black">{{errorDeAcceso}}</p>
                </div>
            </b-modal>
        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import { mapActions } from 'vuex';

import LoginComponent from '../components/LoginItem.vue'

export default {
    components:{
        DefaultLayout,
        LoginComponent
    },
    data(){
        return{
            username: null,
            password: null,
            errorDeAcceso: null,
            emailState: null,
            passwordState: null
        }
    },
    methods:{
        ...mapActions([
            'fetchAccessToken'
        ]),
        goToHome(){
            let $vm = this;
            $vm.$router.push('/')
        },
        /**
         * Solicita acceso para el usuario
         */
        login(){
            let $vm = this;
            // Primero revisamos que se haya llenado correctamente el password
            // y el usuario
            if( $vm.username != null && $vm.password != null && $vm.password != "" && $vm.username.includes("@") ){

                $vm.emailState = true;
                $vm.passwordState = null;
                ApiService.userLogin($vm.username, $vm.password)
                .then( res => {
                    // La respuesta con status 200 indica que las credenciales de acceso son correctas
                    // La respuesta incluye el token de acceso
                    let accessToken = res.data.accessToken;
                    // Almacenamos el token de acceso en el localstorage
                    localStorage.setItem( 'accessToken', JSON.stringify( accessToken ) );

                    $vm.fetchAccessToken();
                    $vm.$router.push('/clientes')
                })
                .catch( err => {
                    // Si la respuesta es incorrecta, entonces se muestra el mensaje de error
                    if( err.response.status == 401 ){
                        if( err.response.data.error == "usuario" ){
                            $vm.errorDeAcceso = "No existe un usuario registrado con este correo";
                            $vm.$refs['error-acceso'].show();
                        }
                        else if( err.response.data.error == "credentials" ){
                            console.log("Error de credenciales")
                            $vm.errorDeAcceso = "Por favor, revise su usuario o contraseña";
                            $vm.$refs['error-acceso'].show();
                        }
                    }
                })
            }
            // En caso contrario, marcamos en donde está el error
            else {
                if( $vm.username == null || !$vm.username.includes("@") || $vm.username == "" ){
                    $vm.emailState = false;
                }
                else{
                    $vm.emailState = true;
                }
                if( $vm.password == null || $vm.password == "" ){
                    $vm.passwordState = false;
                }
                else{
                    $vm.passwordState = null;
                }
            }
        }
    },
    setup() {
        
    },
    created(){
       
    },
    computed: {
        adminUrl(){
            return process.env.VUE_APP_ADMIN_URL;
        },
        mayoristasUrl(){
            return process.env.VUE_APP_MAYORISTAS_URL;
        },
        registroMonumentos(){
            return "/registro/monumentos";
        },
        registroMayoristas(){
            return "/registro/mayoristas";
        }
    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }
    .margen-estandard {
        padding-left: 8% !important;
        padding-right: 8% !important;
    }
    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

    @media (max-width: 1599px) {
        .login-forms .spainhn-subtitle-2-black{
            font-size: 24px;
        }
    }
    @media (max-width: 1440px){
        .margen-estandard {
            padding-left: 3% !important;
            padding-right: 3% !important;
        }
    }
    @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
        }
        .login-forms .spainhn-subtitle-2-black{
            font-size: 18px;
        }
    }
</style>